import Vue from "vue"
import Vuex from "vuex"
import auth from "@/store/auth"
import config from "@/store/config"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: { auth, config },
})

<template>
  <spinner v-if="!isLoggedIn"></spinner>
  <div v-else class="mainlayout">
    <div
      v-if="!isFullLayout"
      class="mainlayout-content container-fluid frieze h-100 px-5 pt-4"
    >
      <transition name="fade" mode="out-in">
        <layout-content-header
          v-if="$router.currentRoute.meta.contentTitle"
          :key="$router.currentRoute.meta.contentTitle"
        ></layout-content-header>
      </transition>
      <transition name="fade" mode="out-in">
        <router-view :key="$route.fullPath" class="h-100"></router-view>
      </transition>
    </div>
    <div v-else>
      <transition name="fade" mode="out-in">
        <router-view :key="$route.fullPath" class="h-100"></router-view>
      </transition>
    </div>
    <div v-if="$router.currentRoute.name !== 'home'" class="mainlayout-footer">
      <layout-footer></layout-footer>
    </div>
  </div>
</template>

<script>
import LayoutFooter from "@/layouts/components/LayoutFooter"
import LayoutContentHeader from "@/layouts/components/LayoutContentHeader"
import SsoService from "@/services/technical/SsoService"

import { mapGetters } from "vuex"

export default {
  name: "Layout",
  components: { LayoutFooter, LayoutContentHeader },
  data() {
    return {
      showMenu: false,
    }
  },
  computed: {
    ...mapGetters("auth", ["isLoggedIn", "areCookiesAccepted"]),
    isFullLayout() {
      return this.$route.meta.hasOwnProperty("fullLayout")
        ? this.$route.meta.fullLayout
        : false
    },
  },
  watch: {
    $route() {
      // refresh token if necessary on route change
      SsoService.refreshToken()
    },
  },
}
</script>

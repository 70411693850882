import store from "@/store/index"
import i18n from "@/i18n/index"
import SsoService from "@/services/technical/SsoService"

function getI18nDefaultRoute(route) {
  const lang = store.getters["config/getCurrentLang"]
  return { path: `/${lang}${route.path}` }
}

function getI18nRoute(route) {
  if (!route.params || !route.params.hasOwnProperty("lang")) {
    if (!route.params) {
      route["params"] = {}
    }
    route.params["lang"] = store.getters["config/getCurrentLang"]
  }
  return route
}

const checkGroupId = (to, next) => {
  if (to.meta.hasOwnProperty("maxGroupId")) {
    if (store.getters["auth/getCurrentUserGroupId"] <= to.meta.maxGroupId) {
      next()
      return
    }
    next(getI18nRoute({ name: "403" }))
    return
  }
  next()
}

const setLanguage = (to, from, next) => {
  const lang = to.params.hasOwnProperty("lang") ? to.params.lang : false
  // console.log("setLanguage", lang, to)

  if (lang && store.getters["config/isLangAvailable"](lang) === false) {
    // console.log("lang unknown", lang, "add default one")
    return next(getI18nDefaultRoute(to))
  }

  if (lang) {
    if (lang === store.getters["config/getCurrentLang"]) {
      // console.log("lang ok")
      if (to.name !== undefined) {
        // console.log("continue", to)
        return next()
      }
      // console.log("redirect 404", getI18nRoute({ name: "404" }))
      return next(getI18nRoute({ name: "404" }))
    }

    if (i18n.messages.hasOwnProperty(lang)) {
      // console.log("lang update requested", lang)
      // requested lang update
      store.dispatch("config/setCurrentLang", lang)
      return next()
    }
  }
  // lang unknown or missing set default one
  if (lang) {
    // console.log("lang is unknown", to)
    to.params.lang = store.getters["config/getCurrentLang"]
    // console.log("add default lang", to)
    next(to)
  } else {
    // console.log(
    //   "lang is missing, add it",
    //   `/${store.getters["config/getCurrentLang"]}${to.path}`
    // )
    // next({ path: `/${store.getters["config/getCurrentLang"]}${to.path}` })
    next(getI18nDefaultRoute(to))
  }
}

const beforeEnter = (to, from, next) => {
  setLanguage(to, from, next)
}

const logout = async (to, from, next) => {
  // console.log("logout")
  try {
    // Clear local storage before logout
    // (do it before because once logout is done, nothing can be done anymore !)
    console.log("clear localStorage", localStorage)
    localStorage.clear()
    // TODO reset stores (CSO_854)
    await store.dispatch("auth/logout")
  } catch (e) {
    console.error("logout failed: ", e)
  }
}

const onSsoLoginSuccess = async (context) => {
  try {
    // console.log("fetchLoggedUser")
    await store.dispatch("auth/fetchLoggedUser")
    // console.log("fetchLoggedUser success")
  } catch (e) {
    console.error("guards fetchLoggedUser failed: ", e)
    if (e && e.status && e.status > 403) {
      // axios interceptor should have already push a route => stop guard here
      throw e
    }
    await store.dispatch("auth/logout")
  }
  checkGroupId(context.to, context.next)
}

const onSsoLoginFailed = (context, reject) => {
  console.error("login failed", context, reject)
  next(getI18nRoute({ name: "403" }))
}

const requireAuth = async (to, from, next) => {
  // console.log("requireAuth for " + to.path, to.params)
  if (!SsoService.isAuthenticated()) {
    // console.warn("Need authentication")
    SsoService.init({ to: to, next: next }, onSsoLoginSuccess, onSsoLoginFailed)
  } else {
    next()
  }
}

const requireAcceptedCookies = async (to, from, next) => {
  if (!store.getters["auth/areCookiesAccepted"]) {
    next(getI18nRoute({ name: "home" }))
    return
  }
  next()
}

export default {
  requireAuth,
  requireAcceptedCookies,
  beforeEnter,
  logout,
}

<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-auto mr-auto">&copy; CAAREA. All rights reserved</div>
        <div class="col-auto">
          <a href="">Mentions légales</a>
          <a href="">Politique de confidentialité</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "LayoutFooter",
}
</script>

<template>
  <div class="page-login h-100">
    <div class="container-fluid h-100">
      <div class="row h-100">
        <div class="col p-0 bg-page">
          <div class="block-login text-center">
            <img
              src="https://res.cloudinary.com/caarea/image/upload/v1617955697/cs/assets/logo-caarea_ebk5o1.svg"
              alt="Caarea Solutions"
              title="Caarea Solutions"
              class="logo"
            />
            <div
              class="alert alert-warning alert-dismissible fade"
              :class="{ show: hasBeenDisconnect, 'mt-5': hasBeenDisconnect }"
              role="alert"
              data-cy="disconnect-alert"
            >
              <strong>Oops !</strong>
              {{ $t("login.disconnect") }}
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
                @click="hasBeenDisconnect = false"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <h1>{{ $t("login.claim") }}</h1>
            <div v-if="loginError" class="alert alert-danger mt-5 mb-5" role="alert">
              {{ loginError }}
            </div>

            <form class="form-simple" :class="{ 'mt-5': loginError }">
              <FormInput
                v-model="form.username"
                name="username"
                :placeholder="$t('login.login')"
                :errors="getLoginErrors"
              />
              <FormInput
                v-model="form.password"
                name="password"
                type="password"
                :placeholder="$t('login.password')"
                :errors="getLoginErrors"
              />
              <button class="btn btn-primary" data-cy="submit" @click.prevent="login">
                {{ $t("button.login") }}
              </button>
            </form>
          </div>
        </div>
        <div class="col p-0 bg-login"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "Login",
  data: function () {
    return {
      form: {
        username: "",
        password: "",
      },
      hasBeenDisconnect: false,
      nextUrl: null,
    }
  },
  computed: {
    ...mapGetters("auth", ["getLoginErrors"]),
    loginError() {
      if (this.getLoginErrors.hasOwnProperty("non_field_errors")) {
        return this.getLoginErrors.non_field_errors
      }
      return null
    },
  },
  created() {
    if (this.$route.query.hasOwnProperty("disconnect")) {
      this.hasBeenDisconnect = true
      this.nextUrl = this.$route.query.disconnect
      setTimeout(() => {
        this.hasBeenDisconnect = false
      }, 5000)
    } else if (this.$route.query.hasOwnProperty("next")) {
      this.nextUrl = this.$route.query.next
    }
  },
  methods: {
    async login() {
      try {
        await this.$store.dispatch("auth/login", this.form)
        this.$router.push(this.nextUrl ? this.nextUrl : { name: "privateZone" })
      } catch (error) {
        console.error("login error", error)
      }
    },
  },
}
</script>

<template>
  <div class="container-fluid">
    <div class="row vh-100">
      <div class="col-9 background-caarea">
        <div class="caarea-text vh-100">
          <div
            class="caarea-text__welcome d-flex flex-column w-75 mx-auto align-items-start"
          >
            <div class="d-flex align-items-center">
              <span class="separator mr-2"></span>
              <h1 class="m-0 text-white font-effra-bold text-center" data-cy="welcome">
                {{ $t("home.welcome") }}
              </h1>
            </div>
            <h2
              class="px-2 text-white text-center font-size-36 font-effra-normal"
              data-cy="caarea-space"
            >
              {{ $t("home.caareaSpace") }}
            </h2>
          </div>
          <div class="caarea-text__app">
            <span class="font-effra-normal font-size-36">
              {{ yourApplicationStart }}
            </span>
            <span class="font-effra-bold font-size-36">{{ affinitechLabel }}</span>
            <span class="font-effra-normal font-size-36">
              {{ yourApplicationEnd }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-3 mt-5 caarea-space">
        <div class="d-flex flex-column align-items-center">
          <a href="/">
            <img
              src="https://res.cloudinary.com/caarea/image/upload/v1617955697/cs/assets/logo-header_ef6uoh.png"
              alt="Caarea Solutions"
            />
          </a>
          <div class="d-flex mt-3">
            <em class="icon-user font-size-18 mr-3"></em>
            <span class="font-effra-normal">{{ $t("home.myAccount") }}</span>
          </div>
          <div class="col-auto dropdown show">
            <button
              id="languageDropdownMenuButton"
              class="invisible-button dropdown-btn dropdown-toggle text-uppercase"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ currentLang }}
            </button>

            <div class="dropdown-menu" aria-labelledby="languageDropdownMenuButton">
              <button
                v-for="lang in languages"
                :key="lang"
                class="dropdown-item dropdown-btn text-uppercase"
                :data-cy="`change-language-btn-${lang}`"
                :disabled="lang === getCurrentLang"
                @click.prevent="onChangeCurrentLanguage(lang)"
              >
                {{ lang }}
              </button>
            </div>
          </div>
        </div>
        <div v-for="app in displayedApps" :key="app.label" class="caarea-app">
          <div
            class="caarea-label"
            :class="{ 'caarea-label--disabled': app.disabled }"
            @click="onCtaClick(app.url, app.disabled)"
          >
            <i
              class="font-size-36 caarea-label__icon"
              :class="[app.disabled ? app.icon + ' caarea-label__disabled' : app.icon]"
              aria-hidden="true"
            ></i>
            <div class="caarea-label__text d-flex flex-column align-items-start">
              <span
                class="text-uppercase font-size-12 font-effra-normal"
                :class="{ 'caarea-label__disabled': app.disabled }"
              >
                {{ caareaLabel }}
              </span>
              <span
                class="text-uppercase font-size-30 font-effra-normal"
                :class="{ 'caarea-label__disabled': app.disabled }"
              >
                {{ app.label }}
              </span>
            </div>
          </div>
        </div>
        <div class="d-flex" :class="'justify-content-' + (version ? 'between' : 'end')">
          <div v-if="version" class="d-flex align-items-center mt-5">
            <p class="font-effra-normal font-size-16 m-0">v{{ version }}</p>
          </div>
          <div class="d-flex align-items-center mt-5">
            <em class="icon-exit-right font-size-18 mr-1"></em>
            <p class="font-effra-normal font-size-16 m-0">
              <router-link data-cy="disconnect-link" :to="{ name: 'logout' }">
                {{ $t("home.logout") }}
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
    <transition appear name="slide">
      <div
        v-if="!user.profile.accepted_cookies"
        id="overlay"
        data-cy="cookies-overlay"
        class="text-white"
      >
        <h1 class="pl-4 pt-3" data-cy="cookies-title">
          {{ $t("home.cookies.title") }}
        </h1>
        <div class="pt-4 px-5">
          <p class="mr-3" data-cy="cookies-description">
            {{ $t("home.cookies.description") }}
          </p>
          <div class="row">
            <div class="col-10">
              <p data-cy="cookies-description-2">
                {{ $t("home.cookies.description2") }}
              </p>
            </div>
            <div class="col-2 pt-4 pr-5 text-right">
              <button
                type="button"
                class="btn btn-primary"
                data-toggle="modal"
                data-target=".bd-example-modal-lg"
                data-cy="accept-cookies"
                @click="onClickAcceptCookies"
              >
                {{ $t("button.accept") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import LangMixin from "@/mixins/LangMixin"
import i18n from "@/i18n/index"

export default {
  name: "Home",
  mixin: [LangMixin],
  data() {
    return {
      user: null,
      caareaLabel: "caarea",
      affinitechLabel: "AFFINITECH",
      version: process.env.VUE_APP_VERSION,
      apps: [
        {
          label: "Products",
          appName: "caarea_products",
          icon: "icon-beaker",
          url: process.env.VUE_APP_PRODUCTS_URL,
          disabled: false,
        },
        {
          label: "Sales",
          appName: "caarea_sales",
          icon: "icon-coin-dollar",
          url: process.env.VUE_APP_SALES_URL,
          disabled: false,
        },
        {
          label: "My Caarea",
          appName: "caarea_my_caarea",
          icon: "icon-caarea-people",
          url: process.env.VUE_APP_MY_CAAREA_URL,
          disabled: false,
        },
        {
          label: "Claims",
          appName: "caarea_claims",
          icon: "icon-warning",
          url: process.env.VUE_APP_CLAIMS_URL,
          disabled: true,
        },
        {
          label: "Finance",
          appName: "caarea_finance",
          icon: "icon-cash-dollar",
          url: process.env.VUE_APP_FINANCE_URL,
          disabled: false,
        },
        {
          label: "API Portal",
          appName: "caarea_api_portal",
          icon: "icon-share2",
          url: process.env.VUE_APP_API_PORTAL_URL,
          disabled: false,
        },
        {
          label: "Monitoring",
          appName: "caarea_api_monitoring",
          icon: "icon-speed-fast",
          url: process.env.VUE_APP_API_MONITORING,
          disabled: true,
        },
      ],
    }
  },
  computed: {
    ...mapGetters("auth", ["getCurrentUser", "getUserLang"]),
    ...mapGetters("config", ["getCurrentLang", "isLangAvailable"]),
    currentLang() {
      return this.getCurrentLang
    },
    displayedApps() {
      // technical debt : add monitoring app with this hack.
      //todo : add monitoring app in keycloack, then use this filter function
      /*
      return this.apps.filter((app) =>
        this.getCurrentUser.profile.list_apps.includes(app.appName)
      )
       */
      return this.apps
    },
    languages() {
      let languages = []
      for (const lang of Object.keys(i18n.messages)) {
        if (lang === this.getCurrentLang) {
          languages.unshift(lang)
        } else {
          languages.push(lang)
        }
      }
      return languages
    },
    yourApplicationStart() {
      switch (this.currentLang) {
        case "en":
          return `${this.$t("home.yourApplication").split(" ")[0]}`
        default:
          return `${this.$t("home.yourApplication")}`
      }
    },
    yourApplicationEnd() {
      switch (this.currentLang) {
        case "en":
          return `${this.$t("home.yourApplication").split(" ")[1]}`
        default:
          return ``
      }
    },
    iconStyle() {
      return "caarea-label__disabled"
    },
  },
  created() {
    if (this.displayedApps.length === 1) {
      window.location.href = this.displayedApps[0].url
    }
    this.user = this.getCurrentUser
    this.$store.dispatch("config/setUserLang")
    const to = this.$router.resolve({ params: { lang: this.getUserLang } })
    if (this.$route.params.lang !== to.location.params.lang) {
      this.$router.push(to.location)
    }
    this.setPageTitle("home.title")
  },
  methods: {
    onCtaClick(url, isDisabled) {
      if (!isDisabled) {
        let newWindow = window.open()
        newWindow.opener = null
        newWindow.location = url
      }
    },
    async onClickAcceptCookies() {
      await this.$store.dispatch("auth/acceptCookies", this.user.id)
      this.user = this.getCurrentUser
    },
    async onChangeCurrentLanguage(newLang) {
      await this.$store.dispatch("config/setCurrentLang", newLang)
      const payload = {
        userId: this.getCurrentUser.id,
        lang: newLang,
      }
      await this.$store.dispatch("auth/updateLang", payload)
      const to = this.$router.resolve({ params: { lang: newLang } })
      await this.$router.push(to.location)
    },
  },
}
//primavera,  zorro
</script>

<style lang="scss" scoped>
.background-caarea {
  background-image: url("https://res.cloudinary.com/caarea/image/upload/v1617955698/cs/assets/splash-screen-caarea_i7zhaa.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.caarea-text {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 5rem;
  color: white;
  &__welcome {
    grid-column: 1/4;
    grid-row: 2/3;
    h1 {
      margin-top: 30px;
      font-size: 50px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  &__app {
    grid-column: 3/5;
    grid-row: 4/5;
  }
}
.separator {
  border-left: 2px solid white;
  height: 2rem;
}
.caarea-space {
  background: white;
  display: grid;
  grid-template-rows: repeat(7, 1fr);
}

.caarea-app {
  text-align: center !important;
  color: $primary;
}

.caarea-app:hover {
  cursor: pointer;
}

.caarea-label {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  border-bottom: 1px solid black;
  padding: 5px;
  &__icon {
    grid-column: 1/2;
  }
  &__text {
    grid-column: 2/4;
  }
  &--disabled {
    border-bottom: 1px solid #c4c4c4;
  }
}

#overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 25%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(106, 102, 94, 0.7);
  z-index: 2;
}
.slide-enter-active {
  transition: all 1.2s ease-in-out;
}
.slide-leave-active {
  transition: all 1.2s ease-out;
}

.caarea-label__disabled {
  color: #c4c4c4;
  cursor: default;
}

@media only screen and (min-width: 1600px) {
  .caarea-text {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>

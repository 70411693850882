<template>
  <div class="mainlayout">
    <div class="mainlayout-content login-frieze">
      <router-view></router-view>
    </div>
    <div class="mainlayout-footer">
      <layout-footer></layout-footer>
    </div>
  </div>
</template>

<script>
import LayoutFooter from "@/layouts/components/LayoutFooter"

export default {
  name: "NoLayout",
  components: { LayoutFooter },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = this.$t(to.meta.title) || "Caarea Solutions"
      },
    },
  },
}
</script>

import Vue from "vue"
import VueI18n from "vue-i18n"
import en from "@/i18n/en"
import fr from "@/i18n/fr"
import es from "@/i18n/es"
import ko from "@/i18n/ko"

Vue.use(VueI18n)
const messages = {
  en: en,
  fr: fr,
  es: es,
  ko: ko,
}

export default new VueI18n({
  locale: "fr",
  messages,
})

import axios from "axios"
import router from "@/router"
import RouterHelper from "@/helpers/router.helper.js"
import SsoService from "@/services/technical/SsoService"
export default {
  initialize() {
    axios.defaults.withCredentials = true
    axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT
    axios.defaults.headers.common["X-Api-Key"] = process.env.VUE_APP_API_SECRET
    axios.defaults.headers.common["Sso-Client-Id"] = process.env.VUE_APP_SSO_CLIENT_ID
    axios.interceptors.request.use(async (req) => {
      const token = await SsoService.refreshToken()
      if (token) {
        req.headers.common["Authorization"] = `Bearer ${token}`
      }
      return req
    })
    axios.interceptors.response.use(
      undefined,
      async (err) => await this.errorHandler(err)
    )
  },
  setAuthorizationSharedTokenHeader(accessToken) {
    axios.defaults.headers.common["Authorization"] = "Shared-Token " + accessToken
  },
  resetAuthorizationHeader() {
    delete axios.defaults.headers.common["Authorization"]
  },
  async errorHandler(error) {
    console.error("errorHandler", error, error.response)
    if (error.response) {
      // Authentication failure
      if (error.response.status === 401) {
        // console.log("auth failure 401")
        SsoService.logout()
        router.push(RouterHelper.getI18nRoute({ name: "403" }))
        return Promise.reject(error.response)
      }

      if (error.response.status === 403) {
        // console.log("auth failure 403")
        router.push(RouterHelper.getI18nRoute({ name: "403" }))
        return Promise.reject(error.response)
      }

      // Forward error < 500 and not 401
      if (error.response.status < 500) {
        console.warn("Axios interception error < 500", error)
        return Promise.reject(error)
      }
    }
    // Unknown Error => redirect to 500
    console.error("Axios unknown error", error.response)
    router.push(RouterHelper.getI18nRoute({ name: "500" }))
    return Promise.reject(error.response)
  },
}

import login from "./views/login.json"
import spinner from "./spinner.json"
import button from "./button.json"
import burgermenu from "./layouts/burgermenu.json"
import home from "./views/home.json"
import error from "./views/error.json"

export default {
  login,
  burgermenu,
  home,
  error,
  spinner,
  button,
}
